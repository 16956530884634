import React from "react";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import "./WPHeaderFooterPage.scss";

const WPHeaderFooterPage = ({ children }) => (
  <div className='header-footer-page-container'>
    <div className='brand-logo-container'>
      <WaveProBrandLogo />
    </div>
    <div className='main-content'>{children}</div>
    {/* Required this empty div to adjust the footer added in app */}
    <div className='footer-container'></div>
  </div>
);

export default WPHeaderFooterPage;

const DropdownIconDown = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
    <path
      d='M9.65179 2.14453H8.81473C8.75781 2.14453 8.70424 2.17243 8.67076 2.21819L5.5 6.58873L2.32924 2.21819C2.29576 2.17243 2.24219 2.14453 2.18527 2.14453H1.34822C1.27567 2.14453 1.23326 2.22712 1.27567 2.28627L5.21094 7.7115C5.3538 7.90792 5.64621 7.90792 5.78795 7.7115L9.72322 2.28627C9.76674 2.22712 9.72433 2.14453 9.65179 2.14453Z'
      fill='black'
    />
  </svg>
);

export default DropdownIconDown;

import React, { Component } from "react";
import { connect } from "react-redux";

import API_ERROR_CODE, { FULL_SCREEN_ERROR_CODES, HTTP_STATUS_CODE } from "@constants/api.errorcode";

import DupontLogger from "@utils/DupontLogger";

import WPErrorFullPage from "@features/WPErrorFullPage";

class WPErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorData: null };
    this.logger = DupontLogger("ErrorBoundary");
  }

  static getDerivedStateFromError() {
    const errorData = { status: API_ERROR_CODE.APP_CRASH };
    return { hasError: true, errorData };
  }

  render() {
    let { apiError, children } = this.props;
    const { hasError, errorData } = this.state;

    if (apiError) {
      const { status, data } = apiError;
      let isUnderMaintenance = false;
      if (status === HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) {
        isUnderMaintenance = data?.errorCode === API_ERROR_CODE.PRODUCTION_DEPLOYMENT;
        if (!isUnderMaintenance) {
          apiError = { status: API_ERROR_CODE.APP_CRASH };
        }
      }
      if (isUnderMaintenance || FULL_SCREEN_ERROR_CODES.includes(apiError.status)) {
        return <WPErrorFullPage errorData={apiError} />;
      }
    } else if (hasError) {
      return <WPErrorFullPage errorData={errorData} />;
    }

    return children;
  }
}

const mapStateToProps = state => ({
  apiError: state.apiData.apiError,
});

export default connect(mapStateToProps)(WPErrorBoundary);

import { SortIconAscending, SortIconDescending } from "@components/SVGs";

import { HomeStrings } from "../../constants/HomeStrings";

export const HomeSortOption = {
    createdDate: "sort_by_created_date",
    modifiedDate: "sort_by_modified_date",
    nameAscending: "sort_by_name_ascending",
    nameDescending: "sort_by_name_descending",
};

export const HomeSortByOptions = [
    { id: "sort_by_created_date", label: HomeStrings.dateCreated, value: HomeSortOption.createdDate, },
    { id: "sort_by_modified_date", label: HomeStrings.lastModified, value: HomeSortOption.modifiedDate },
    { id: "sort_by_name_ascending", label: HomeStrings.ascending, value: HomeSortOption.nameAscending, icon: <SortIconAscending /> },
    { id: "sort_by_name_descending", label: HomeStrings.descending, value: HomeSortOption.nameDescending, icon: <SortIconDescending /> },
];
const API_ERROR_CODE = {
    APP_CRASH: "APPLICATION_CASHED",
    PRODUCTION_DEPLOYMENT: "PRODUCTION_DEPLOYMENT",
};

export const HTTP_STATUS_CODE = {
    // 2XX Success
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    // 4XX Client Errors
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    // 5XX Server Errors
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};

export const FULL_SCREEN_ERROR_CODES = [
    // HTTP_STATUS_CODE.SERVICE_UNAVAILABLE,
    HTTP_STATUS_CODE.UNAUTHORIZED,
    HTTP_STATUS_CODE.FORBIDDEN,
    API_ERROR_CODE.APP_CRASH
];

export default API_ERROR_CODE;
import { Fragment, lazy, Suspense, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { FullScreenLoader } from "@components/FullScreenLoader";

import { updateProjectInfo } from "./common/ProjectInfoSlice";
import { updateUserInfo } from "./common/UserInfoSlice";
import { clearAuthData, setAccessTokens, setUserAttributes } from "./features/login/AuthSlice";
import PrivateRoute from "./PrivateRoute";
import WP_App_Routes, { WP_Public_Routes } from "./Routes";

const FeedWaterHome = lazy(() => import("./features/feedwater/FeedWaterHome"));
const Home = lazy(() => import("./features/home/Home"));
const SalesforceCallback = lazy(() => import("./features/login/SalesforceCallback"));
const SalesforceLogin = lazy(() => import("./features/login/SalesforceLogin"));
const AboutWavePro = lazy(() => import("./features/aboutWavePro"));
const HelpFAQWavePro = lazy(() => import("./features/helpFAQWavePro"));
const LogoutWavePro = lazy(() => import("./features/sso/SSOLogout"));

function Routing() {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessTokens = JSON.parse(localStorage.getItem("accessTokens"));
    const loggedInData = JSON.parse(localStorage.getItem("loggedInData"));
    const ProjectInfo = JSON.parse(localStorage.getItem("ProjectInfo"));
    const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));

    if (accessTokens) {
      dispatch(setAccessTokens(accessTokens));
      dispatch(setUserAttributes(loggedInData));
    }

    const { pathname } = window.location;
    const isPublicRoute = WP_Public_Routes.includes(pathname);
    if (!isPublicRoute) {
      if (ProjectInfo) {
        dispatch(updateProjectInfo(ProjectInfo));
      }
      if (UserInfo) {
        dispatch(updateUserInfo(UserInfo));
      }
    }

    dispatch(clearAuthData());
    window.localStorage.clear();
  }, [dispatch]);

  const routes = useMemo(() => (
    <Routes>
      <Route exact path={WP_App_Routes.about} element={<AboutWavePro />} />
      <Route exact path={WP_App_Routes.help} element={<HelpFAQWavePro />} />
      <Route exact path={WP_App_Routes.logout} element={<LogoutWavePro />} />
      <Route path={WP_App_Routes.base} element={<SalesforceLogin />} />
      <Route exact path='/oauth2/callback/' element={<SalesforceCallback />} />
      <Route exact path={WP_App_Routes.home} element={<PrivateRoute />}>
        <Route exact path={WP_App_Routes.home} element={<Home />} />
      </Route>
      <Route exact path={WP_App_Routes.project} element={<PrivateRoute />}>
        <Route exact path={WP_App_Routes.project} element={<FeedWaterHome />} />
      </Route>
      <Route path={WP_App_Routes.wildcard} element={<Navigate to={WP_App_Routes.base} />} />
    </Routes>
  ), []);

  return (
    <Fragment>
      <div style={{ minHeight: "100vh" }}>
        <Suspense fallback={<FullScreenLoader />}>
          {routes}
        </Suspense>
      </div>
    </Fragment>
  );
}

export default Routing;
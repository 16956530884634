import React from "react";

const FolderIcon = ({ opened }) => (
  <>
    {opened ? (
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
        <g clipPath='url(#clip0_1010_38608)'>
          <path
            d='M13.5 5.93555H11.8125V4.16055C11.8125 3.88398 11.5891 3.66055 11.3125 3.66055H6.39062L4.55781 1.90742C4.53449 1.88559 4.50382 1.87332 4.47188 1.87305H0.5C0.223437 1.87305 0 2.09648 0 2.37305V11.623C0 11.8996 0.223437 12.123 0.5 12.123H11.4062C11.6094 12.123 11.7938 11.9996 11.8703 11.8105L13.9641 6.62305C13.9875 6.56367 14 6.49961 14 6.43555C14 6.15898 13.7766 5.93555 13.5 5.93555ZM1.125 2.99805H4.07031L5.93906 4.78555H10.6875V5.93555H2.71875C2.51563 5.93555 2.33125 6.05898 2.25469 6.24805L1.125 9.04805V2.99805ZM11.0516 10.998H1.48437L3.09844 6.99805H12.6672L11.0516 10.998Z'
            fill='black'
          />
        </g>
        <defs>
          <clipPath id='clip0_1010_38608'>
            <rect width='14' height='14' fill='white' />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
        <path
          d='M12.75 3.66055H7.14063L5.30781 1.90742C5.28449 1.88559 5.25382 1.87332 5.22188 1.87305H1.25C0.973438 1.87305 0.75 2.09648 0.75 2.37305V11.623C0.75 11.8996 0.973438 12.123 1.25 12.123H12.75C13.0266 12.123 13.25 11.8996 13.25 11.623V4.16055C13.25 3.88398 13.0266 3.66055 12.75 3.66055ZM12.125 10.998H1.875V2.99805H4.82031L6.68906 4.78555H12.125V10.998Z'
          fill='black'
        />
      </svg>
    )}
  </>
);

export default FolderIcon;

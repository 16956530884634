import SVGContainerPath from "../SVGContainerPath";

const QuestionIcon = () => (
  <SVGContainerPath xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g clipPath='url(#clip0_2267_134231)'>
      <path
        d='M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z'
        fill='#007672'
      />
      <path
        d='M14.4907 7.63993C13.8211 7.05287 12.9372 6.73145 11.9997 6.73145C11.0622 6.73145 10.1782 7.05511 9.50858 7.63993C8.81215 8.2493 8.42822 9.0685 8.42822 9.94573V10.1154C8.42822 10.2136 8.50858 10.2939 8.60679 10.2939H9.67822C9.77644 10.2939 9.85679 10.2136 9.85679 10.1154V9.94573C9.85679 8.96136 10.8188 8.16002 11.9997 8.16002C13.1805 8.16002 14.1425 8.96136 14.1425 9.94573C14.1425 10.6399 13.6514 11.2761 12.8903 11.5685C12.4171 11.7493 12.0153 12.0663 11.7273 12.4814C11.4349 12.9056 11.2831 13.4145 11.2831 13.9301V14.41C11.2831 14.5082 11.3635 14.5886 11.4617 14.5886H12.5331C12.6313 14.5886 12.7117 14.5082 12.7117 14.41V13.9033C12.7129 13.6866 12.7793 13.4753 12.9023 13.2969C13.0254 13.1185 13.1993 12.9814 13.4014 12.9033C14.7184 12.3966 15.5688 11.2359 15.5688 9.94573C15.5711 9.0685 15.1872 8.2493 14.4907 7.63993ZM11.1068 16.91C11.1068 17.1468 11.2009 17.3739 11.3683 17.5414C11.5357 17.7088 11.7629 17.8029 11.9997 17.8029C12.2365 17.8029 12.4636 17.7088 12.631 17.5414C12.7984 17.3739 12.8925 17.1468 12.8925 16.91C12.8925 16.6732 12.7984 16.4461 12.631 16.2787C12.4636 16.1112 12.2365 16.0172 11.9997 16.0172C11.7629 16.0172 11.5357 16.1112 11.3683 16.2787C11.2009 16.4461 11.1068 16.6732 11.1068 16.91Z'
        fill='#007672'
      />
    </g>
    <defs>
      <clipPath id='clip0_2267_134231'>
        <rect width='24' height='24' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </SVGContainerPath>
);

export default QuestionIcon;

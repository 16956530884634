import React from "react";
import { useSelector } from "react-redux";

import useProjectDetails from "@hooks/useProjectDetails";

import WaveProImage from "@common/assets/images/Wave-PRO-UF-Logo-02.svg";

import FooterStyled from "./FooterStyled";
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const Footer = ({ locationCheck, tabCheck }) => {
  const userProfileDetails = useSelector(state => state.userInfo.data);
  const { IS_UF_PROJECT } = useProjectDetails();
  const isNewUfReport = process.env.REACT_APP_REPORT_NEW === "true" && IS_UF_PROJECT;
  return (
    <>
      <FooterStyled id='footer-container' locationCheck={locationCheck} tabCheck={tabCheck} isNewUfReport={isNewUfReport}>
        <div className='footer-list'>
          <div className='footer_list_items'>
            <a href='https://www.dupont.com/' target='__blank' rel='noopener'>
              DuPont.com
            </a>
            <a className='vertical_line'></a>
            <a href='https://www.dupont.com/privacy.html' target='__blank' rel='noopener'>
              Privacy
            </a>
            <a className='vertical_line'></a>
            <a href='https://www.dupont.com/legal-notices-and-terms-of-use.html' target='__blank' rel='noopener'>
              Legal Notice & Terms of use
            </a>
            <a className='vertical_line'></a>
            <a href='https://www.dupont.com/accessibility.html' target='__blank' rel='noopener'>
              Accessibility
            </a>
            <a className='vertical_line'></a>
            <a id='teconsent'></a>
          </div>
          <div className='copyright'>
            <p>
              © {currentYear} DuPont. All rights reserved. DuPont™, the DuPont Oval Logo, and all trademarks and
              service marks denoted with ™, ℠ or ® are owned by affiliates of DuPont de Nemours, Inc. unless otherwise
              noted.
            </p>
          </div>
        </div>
        <div className='footer-logo'>
          <div className='logo-text'>
            <h6>Water Application Value Engine</h6>
            <p className='text-right color1'>DuPont Water Solutions</p>
            {userProfileDetails?.applicationVersion && <p className='color2'>{` Version:${userProfileDetails?.applicationVersion}`}</p>}
          </div>
          <div>
            <img src={WaveProImage} alt='WaveProLogo' />
          </div>
        </div>
      </FooterStyled>
    </>
  );
};

export default Footer;

import React from "react";

const ImportedProjectsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0_1010_24669)">
      <path d="M9.41919 7.63653L6.117 4.26565C6.08568 4.23402 6.04573 4.21631 6.00361 4.21631H5.04787C4.96796 4.21631 4.93124 4.33271 4.99171 4.39344L7.99585 7.46193H0.0863941C0.0388772 7.46193 0 7.50748 0 7.56315V8.32228C0 8.37795 0.0388772 8.4235 0.0863941 8.4235H7.99477L4.99063 11.492C4.93016 11.554 4.96688 11.6691 5.04679 11.6691H6.03493C6.05544 11.6691 6.07596 11.6603 6.09108 11.6438L9.41919 8.2489C9.45658 8.21081 9.48655 8.16377 9.5071 8.11095C9.52764 8.05813 9.53827 8.00076 9.53827 7.94271C9.53827 7.88467 9.52764 7.8273 9.5071 7.77448C9.48655 7.72166 9.45658 7.67462 9.41919 7.63653Z" fill="black" fillOpacity="0.85" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.0091 3.68969L9.72416 0.404719C9.63257 0.313131 9.50893 0.26123 9.37918 0.26123H2.89472C2.62454 0.26123 2.40625 0.479516 2.40625 0.749702V6.32205H3.50531V1.36029H8.11526V4.65747C8.11526 4.82751 8.1828 4.99058 8.30304 5.11081C8.42327 5.23104 8.58634 5.29859 8.75638 5.29859H12.0536V12.8394H3.50531V9.46185H2.40625V13.45C2.40625 13.7201 2.62454 13.9384 2.89472 13.9384H12.6641C12.9343 13.9384 13.1526 13.7201 13.1526 13.45V4.0362C13.1526 3.90645 13.1007 3.78128 13.0091 3.68969ZM9.15326 4.26069H12.0261L9.15326 1.38787V4.26069Z" fill="black" fillOpacity="0.85" />
    </g>
    <defs>
      <clipPath id="clip0_1010_24669">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ImportedProjectsIcon;

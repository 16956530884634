import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { UNIT_TYPES, UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";

import { SYSTEM_DESIGN_WATER } from "@features/feedwater/systemdesign/constants/SystemDesignConstant";
import { DEFAULT_WATER_TYPE } from "@features/feedwater/uf/constants/UFConstants";
import { getFiltrateValue } from "@features/feedwater/uf/UFConfiguration/UFHelper";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const UFDiagramFeedWaterDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName, convertFromMetric, activeUnits } = useUnitConversion();

  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [feedWaterData, setFeedWaterData] = useState({});
  const [avgFlowRate, setAvgFlowRate] = useState("");
  const { calcEngineData } = useSelector(state => state.UFStore);

  const feedWater_StreamStore = useSelector(state => state.Feedsetupdetailsdatapanel);

  useEffect(() => {
    if (feedWater_StreamStore) {
      const { streamData, waterTypes } = feedWater_StreamStore;
      const savefeedwaterObj = streamData?.lstrequestsavefeedwater?.[0]?.streams?.[0] || {};
      const { toc = "", tss = "", turbidity = "", waterTypeID } = savefeedwaterObj;
      const { waterSource } = waterTypes.find(item => item.waterTypeId === waterTypeID) || DEFAULT_WATER_TYPE;
      setFeedWaterData({ waterSource, toc, tss, turbidity });
    }
  }, [feedWater_StreamStore]);

  useEffect(() => {
    const data = getFiltrateValue(SYSTEM_DESIGN_WATER.FEED_WATER, calcEngineData, unitConfig, unitConversionByName);
    setAvgFlowRate(data || 0);
  }, [calcEngineData, unitConfig.selectedUnits]);

  const xyPosition = useMemo(() => (!isInge || isCIP ? { x: 140, y: 1350 } : { x: 100, y: 1300 }), [isCIP]);

  const isKMnO4 = activeUnits[UNIT_TYPES.ORGANICS] === UNITS.organicsKMnO4;

  const mgL = UNITS.MiligramPerLiter;

  const details = [
    { label: "Average Feed Flow:", value: avgFlowRate },
    { label: "Type:", value: feedWaterData.waterSource },
    { label: "TSS:", value: `${feedWaterData.tss} ${mgL}` },
    {
      label: isKMnO4 ? "COD:" : "TOC:",
      value: `${convertFromMetric(feedWaterData.toc, UNIT_TYPES.ORGANICS)} ${isKMnO4 ? UNITS.organicsKMnO4 : mgL}`,
    },
    { label: "Turbidity:", value: `${feedWaterData.turbidity} NTU` },
  ];

  return (
    <g id='feed_water_details'>
      <BoldTextElement {...xyPosition}>Feed Water</BoldTextElement>
      <TextElement fill='#454545'>
        {details.map((detail, index) => (
          <tspan key={index} x={xyPosition.x} y={xyPosition.y + (index + 1) * 140}>
            {detail.label} {detail.value}
          </tspan>
        ))}
      </TextElement>
    </g>
  );
};

export default UFDiagramFeedWaterDetails;

import React, { useState } from "react";

import IconButton from "@components/IconButton";
import CloseIcon from "@components/SVGs/CloseIcon";
import ExternalIcon from "@components/SVGs/ExternalIcon";
import MinimizeIcon from "@components/SVGs/MinimizeIcon";
import WPHeading from "@components/WPHeading";

import MinervaIcon from "./minerva-icon";

import "./minerva.scss";

const MinervaIntegration = () => {
  const [showIframe, setShowIframe] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleClick = () => {
    setShowIframe(true);
    setIsMinimized(false);
  };

  const handleMinimize = () => {
    setIsMinimized(true);
  };

  const openInNewTab = () => {
    window.open("https://minerva-dev.dupont.com", "_blank");
  };

  return (
    <div className='wavepro-miverva-container'>
      {(!showIframe || isMinimized) && (
        <div className='miverva-icon-btn'>
          <IconButton onClick={handleClick}>
            <MinervaIcon />
          </IconButton>
        </div>
      )}
      {(isMinimized || showIframe) && (
        <div className={`miverva-container ${isMinimized ? "invisible" : "visible"}`}>
          <div className='miverva-container-header'>
            <WPHeading text='Ask Minerva' />
            <div>
              <IconButton tooltip='Minimize' onClick={() => handleMinimize(false)}>
                <MinimizeIcon />
              </IconButton>
              <IconButton tooltip='Open in new tab' onClick={() => openInNewTab()}>
                <ExternalIcon />
              </IconButton>
              <IconButton tooltip='Exit' onClick={() => setShowIframe(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <iframe
            src='https://minerva-dev.dupont.com'
            title='Minerva'
            style={{ width: "100%", height: "calc(100% - 42px)" }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default MinervaIntegration;

import React from "react";

const AccessDeniedIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='223' height='223' viewBox='0 0 223 223' fill='none'>
    <path
      d='M24.7437 122.083C24.7437 122.083 25.7784 154.195 52.5875 175.603C79.3965 197.011 116.548 198.541 146.622 200.579C176.696 202.618 199.799 185.585 203.264 159.851C206.73 134.116 183.765 126.561 171.295 97.2635C158.825 67.9657 159.953 58.3366 133.572 40.3182C107.191 22.2998 65.9184 33.3651 44.283 64.2238C22.6475 95.0826 24.7437 122.083 24.7437 122.083Z'
      fill='#00768D'
    />
    <path
      opacity='0.7'
      d='M24.7437 122.083C24.7437 122.083 25.7784 154.195 52.5875 175.603C79.3965 197.011 116.548 198.541 146.622 200.579C176.696 202.618 199.799 185.585 203.264 159.851C206.73 134.116 183.765 126.561 171.295 97.2635C158.825 67.9657 159.953 58.3366 133.572 40.3182C107.191 22.2998 65.9184 33.3651 44.283 64.2238C22.6475 95.0826 24.7437 122.083 24.7437 122.083Z'
      fill='white'
    />
    <path
      d='M37.2908 80.1416V68.6481C37.2908 60.5131 44.0522 53.8945 52.3612 53.8945C60.6701 53.8945 67.4315 60.5131 67.4315 68.6481V80.1416H73.4391V68.6481C73.4391 57.2707 63.9839 48.0117 52.3612 48.0117C40.7384 48.0117 31.2832 57.2707 31.2832 68.6481V80.1416H37.2908Z'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M75.071 80.2217H29.0527V118.288H75.071V80.2217Z'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M56.8478 96.0325C56.8089 94.7294 56.2639 93.4927 55.3285 92.5848C54.393 91.6768 53.1406 91.1689 51.837 91.1689C50.5333 91.1689 49.281 91.6768 48.3455 92.5848C47.41 93.4927 46.8651 94.7294 46.8262 96.0325C46.8315 96.9232 47.0793 97.7956 47.5429 98.5562C48.0065 99.3168 48.6684 99.9368 49.4576 100.35L48.6146 108.993H55.0593L54.2164 100.35C55.0056 99.9368 55.6675 99.3168 56.1311 98.5562C56.5947 97.7956 56.8424 96.9232 56.8478 96.0325Z'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.758 119.524L92.2999 118.77V118.583L90.8905 118.676L89.4856 118.583V118.77L78.0279 119.524L69.5762 189.01H78.0279L90.6095 133.608H91.1759L103.758 189.01H112.209L103.758 119.524Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.5756 189.006L67.5106 192.199L61.1239 195.018C60.7875 195.204 60.5062 195.475 60.3088 195.805C60.1114 196.135 60.0049 196.511 60 196.896V199.335H78.2146C78.322 198.023 78.322 196.705 78.2146 195.393C78.0273 193.515 78.0273 189.006 78.0273 189.006H69.5756Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M60 197.271V199.335H78.2146C78.2146 199.335 78.2994 198.443 78.2994 197.271H60Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M112.174 189.006L114.239 192.199L120.625 195.018C120.962 195.204 121.243 195.475 121.44 195.805C121.638 196.135 121.744 196.511 121.749 196.896V199.335H103.535C103.427 198.023 103.427 196.705 103.535 195.393C103.722 193.515 103.722 189.006 103.722 189.006H112.174Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M121.758 197.271V199.335H103.534C103.534 199.335 103.454 198.443 103.449 197.271H121.758Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M73.1749 87.1978C73.1749 87.1978 59.7949 106.001 59.7949 107.379C59.7949 108.757 61.9223 119.399 65.303 120.398C68.6837 121.397 70.5614 119.649 71.1858 116.143C71.8102 112.638 69.6827 107.629 69.6827 107.629L75.9267 101.996L73.1749 87.1978Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M70.5788 115.643C70.5788 115.643 74.459 115.014 75.4625 115.264C76.466 115.514 77.2153 117.896 77.2153 117.896C77.2153 117.896 77.59 121.401 76.9611 122.15C76.3322 122.9 73.3307 123.778 72.0819 124.028C70.8331 124.278 68.1972 121.401 67.1759 119.648C66.1545 117.896 67.9474 114.89 70.5788 115.643Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M75.9891 86.3459L73.1748 87.1978L77.608 116.79H103.958L107.967 87.3673L104.386 86.0025L91.1664 83.1035L75.9891 86.3459Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M105.407 96.238L104.814 100.502L100.207 101.608L95.5146 99.7346L95.8581 95.8945L105.407 96.238Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M100.506 100.502C100.954 100.502 101.318 100.138 101.318 99.6901C101.318 99.2418 100.954 98.8784 100.506 98.8784C100.058 98.8784 99.6943 99.2418 99.6943 99.6901C99.6943 100.138 100.058 100.502 100.506 100.502Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M88.3523 88.5623L90.8276 91.4613L87.6699 111.585L90.9971 116.874L94.9219 111.331L91.5947 91.5461L94.409 88.8166L91.4252 84.981L88.3523 88.5623Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.9658 83.5317L74.624 86.346L74.9674 87.7108L86.0505 84.6378L85.9658 83.5317Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M95.2605 83.188L106.602 86.0022L106.263 87.367L95.1758 84.2985L95.2605 83.188Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M106.594 86.0024C106.594 86.0024 110.884 86.9703 113.511 88.4733C116.138 89.9763 116.767 93.357 115.264 97.1123C113.761 100.868 109.131 100.368 107.004 99.3423C104.877 98.3165 104.248 94.5879 104.622 92.96'
      fill='#00768D'
    />
    <path
      d='M106.594 86.0024C106.594 86.0024 110.884 86.9703 113.511 88.4733C116.138 89.9763 116.767 93.357 115.264 97.1123C113.761 100.868 109.131 100.368 107.004 99.3423C104.877 98.3165 104.248 94.5879 104.622 92.96'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M86.4792 82.0819L85.1992 82.9338L85.5382 91.546L91.082 87.6257L96.88 91.6308L97.3929 83.1033L95.8586 81.8232L86.4792 82.0819Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M97.6742 71.8374C97.6742 71.8374 100.823 71.0703 101.416 67.9216C102.009 64.7728 96.3362 60.9417 92.4025 60.2637C88.4687 59.5858 82.702 63.6667 81.7654 66.4765C80.8288 69.2863 84.486 72.0917 88.4018 72.6893C92.3177 73.2869 97.6742 71.8374 97.6742 71.8374Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.9348 72.3501C85.9348 72.3501 84.2311 69.7098 83.8074 72.0067C83.3837 74.3036 85.1676 75.3249 85.9348 75.2402C86.7019 75.1555 85.9348 72.3501 85.9348 72.3501Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M97.0003 72.3501C97.0003 72.3501 98.704 69.7098 99.1277 72.0067C99.5514 74.3036 97.7897 75.3249 97.0003 75.2402C96.2108 75.1555 97.0003 72.3501 97.0003 72.3501Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M86.3096 77.5596V83.1881L91.0818 87.6258L96.4561 83.0186V77.7335L86.3096 77.5596Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.596 70.6466C85.596 70.6466 85.3373 75.9228 85.7655 77.6266C86.1936 79.3303 88.999 82.9027 91.2111 83.6699C93.4233 84.437 96.4873 79.8387 97.1697 78.3179C97.8521 76.797 97.5934 70.3166 97.5934 70.3166C97.5934 70.3166 96.6613 68.0866 94.7881 67.4265C92.9149 66.7664 87.1258 65.5622 85.596 70.6466Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M89.1331 73.4477C89.1331 73.8491 88.9503 74.1747 88.7228 74.1747C88.4954 74.1747 88.3125 73.8491 88.3125 73.4477C88.3125 73.0463 88.4954 72.7207 88.7228 72.7207C88.9503 72.7207 89.1331 73.0463 89.1331 73.4477Z'
      fill='#263238'
    />
    <path
      d='M94.3558 73.4477C94.3558 73.8491 94.1729 74.1747 93.9455 74.1747C93.718 74.1747 93.5352 73.8491 93.5352 73.4477C93.5352 73.0463 93.718 72.7207 93.9455 72.7207C94.1729 72.7207 94.3558 73.0463 94.3558 73.4477Z'
      fill='#263238'
    />
    <path
      d='M90.0199 75.4814C89.936 75.4614 89.8487 75.4606 89.7644 75.4792C89.6802 75.4978 89.6013 75.5352 89.5336 75.5886C89.4659 75.642 89.4112 75.7101 89.3736 75.7877C89.3359 75.8653 89.3164 75.9505 89.3164 76.0367C89.3164 76.1229 89.3359 76.2081 89.3736 76.2857C89.4112 76.3633 89.4659 76.4314 89.5336 76.4848C89.6013 76.5382 89.6802 76.5756 89.7644 76.5942C89.8487 76.6128 89.936 76.612 90.0199 76.592C90.199 76.5958 90.3739 76.5375 90.5149 76.4269C90.6467 76.5819 90.8101 76.707 90.994 76.7939C91.178 76.8808 91.3783 76.9275 91.5817 76.9309C91.7851 76.9342 91.9869 76.8942 92.1737 76.8135C92.3604 76.7327 92.5278 76.6131 92.6647 76.4626C92.7973 76.5487 92.9525 76.5937 93.1107 76.592C93.1945 76.612 93.2819 76.6128 93.3661 76.5942C93.4503 76.5756 93.5292 76.5382 93.5969 76.4848C93.6646 76.4314 93.7193 76.3633 93.757 76.2857C93.7946 76.2081 93.8141 76.1229 93.8141 76.0367C93.8141 75.9505 93.7946 75.8653 93.757 75.7877C93.7193 75.7101 93.6646 75.642 93.5969 75.5886C93.5292 75.5352 93.4503 75.4978 93.3661 75.4792C93.2819 75.4606 93.1945 75.4614 93.1107 75.4814'
      fill='#CCCCCC'
    />
    <path
      d='M90.0199 75.4814C89.936 75.4614 89.8487 75.4606 89.7644 75.4792C89.6802 75.4978 89.6013 75.5352 89.5336 75.5886C89.4659 75.642 89.4112 75.7101 89.3736 75.7877C89.3359 75.8653 89.3164 75.9505 89.3164 76.0367C89.3164 76.1229 89.3359 76.2081 89.3736 76.2857C89.4112 76.3633 89.4659 76.4314 89.5336 76.4848C89.6013 76.5382 89.6802 76.5756 89.7644 76.5942C89.8487 76.6128 89.936 76.612 90.0199 76.592C90.199 76.5958 90.3739 76.5375 90.5149 76.4269C90.6467 76.5819 90.8101 76.707 90.994 76.7939C91.178 76.8808 91.3783 76.9275 91.5817 76.9309C91.7851 76.9342 91.9869 76.8942 92.1737 76.8135C92.3604 76.7327 92.5278 76.6131 92.6647 76.4626C92.7973 76.5487 92.9525 76.5937 93.1107 76.592C93.1945 76.612 93.2819 76.6128 93.3661 76.5942C93.4503 76.5756 93.5292 76.5382 93.5969 76.4848C93.6646 76.4314 93.7193 76.3633 93.757 76.2857C93.7946 76.2081 93.8141 76.1229 93.8141 76.0367C93.8141 75.9505 93.7946 75.8653 93.757 75.7877C93.7193 75.7101 93.6646 75.642 93.5969 75.5886C93.5292 75.5352 93.4503 75.4978 93.3661 75.4792C93.2819 75.4606 93.1945 75.4614 93.1107 75.4814'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M90.6982 78.9736H92.4778'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.3994 71.9265C85.3994 71.9265 85.4619 73.6392 86.0327 73.1932C86.6036 72.7472 86.7909 69.8392 86.287 69.4601C85.783 69.081 85.3994 71.0434 85.3994 71.9265Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M96.3848 71.9265C96.3848 71.9265 96.4517 73.6392 97.0181 73.1932C97.5845 72.7472 97.7807 69.8392 97.2723 69.4601C96.7639 69.081 96.3848 71.0434 96.3848 71.9265Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.4 70.5349C85.4 70.5349 85.5918 70.691 85.5873 70.7668C86.4124 71.2128 88.3079 72.0335 91.3853 72.1985C93.5679 72.2739 95.7288 71.7444 97.6293 70.6687C97.6293 70.4412 97.6293 70.3074 97.6293 70.303C97.5535 68.3094 96.0728 66.9803 94.2397 66.4718C92.4378 65.9757 90.5243 66.0711 88.7807 66.7439C87.8964 67.0774 87.1162 67.639 86.5194 68.3718C86.292 68.6215 85.0878 70.2851 85.4 70.5349Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M85.5957 70.6465C87.4977 71.0672 89.4507 71.2099 91.3937 71.0702C95.0554 70.9007 97.602 70.6465 97.602 70.6465C95.9193 72.2239 93.7473 73.1777 91.4472 73.3493C90.3239 73.4222 89.2001 73.2132 88.1782 72.7411C87.1562 72.2691 86.2685 71.549 85.5957 70.6465Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M76.248 96.238L76.8412 100.502L81.4484 101.608L86.1359 99.7346L85.7969 95.8945L76.248 96.238Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M81.1496 100.502C81.5979 100.502 81.9613 100.138 81.9613 99.6901C81.9613 99.2418 81.5979 98.8784 81.1496 98.8784C80.7013 98.8784 80.3379 99.2418 80.3379 99.6901C80.3379 100.138 80.7013 100.502 81.1496 100.502Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M104.257 116.518H77.2158V120.772H104.257V116.518Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M82.4115 116.768H76.5287C75.871 116.768 75.3379 117.301 75.3379 117.958V120.835C75.3379 121.493 75.871 122.026 76.5287 122.026H82.4115C83.0691 122.026 83.6023 121.493 83.6023 120.835V117.958C83.6023 117.301 83.0691 116.768 82.4115 116.768Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.945 116.768H98.0619C97.4042 116.768 96.8711 117.301 96.8711 117.958V120.835C96.8711 121.493 97.4042 122.026 98.0619 122.026H103.945C104.602 122.026 105.135 121.493 105.135 120.835V117.958C105.135 117.301 104.602 116.768 103.945 116.768Z'
      fill='#4C4C4C'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M94.1142 116.768H88.2314V120.523H94.1142V116.768Z'
      fill='white'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M69.7008 107.629C69.7008 107.629 65.9455 107.379 64.5674 104.998'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M111.455 96.6126C114.879 96.6126 117.655 93.6972 117.655 90.101C117.655 86.5047 114.879 83.5894 111.455 83.5894C108.031 83.5894 105.256 86.5047 105.256 90.101C105.256 93.6972 108.031 96.6126 111.455 96.6126Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M111.455 91.1535C114.135 91.1535 116.307 89.2067 116.307 86.805C116.307 84.4034 114.135 82.4565 111.455 82.4565C108.775 82.4565 106.603 84.4034 106.603 86.805C106.603 89.2067 108.775 91.1535 111.455 91.1535Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M111.455 87.9558C114.135 87.9558 116.307 86.0089 116.307 83.6073C116.307 81.2057 114.135 79.2588 111.455 79.2588C108.775 79.2588 106.603 81.2057 106.603 83.6073C106.603 86.0089 108.775 87.9558 111.455 87.9558Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.659 74.2327C103.659 74.2327 103.744 80.6461 104.069 82.5372C104.395 84.4282 105.715 86.8946 107.526 86.8946C108.732 86.8648 109.926 86.6435 111.063 86.239C111.063 86.239 113.199 87.3897 115.255 87.0596C117.311 86.7296 116.981 83.8529 116.981 82.5996C116.981 81.3464 116.736 72.074 116.736 72.074C116.736 72.074 116.901 71.182 116.076 71.0081C115.671 70.917 115.259 70.8617 114.845 70.8431V68.8673C114.693 68.641 114.482 68.4604 114.236 68.3449C113.989 68.2294 113.715 68.1833 113.444 68.2117C112.378 68.2117 112.294 68.457 112.294 68.457V66.6462C112.294 66.6462 111.402 65.2056 110.238 65.9058C110.003 66.0405 109.805 66.2311 109.662 66.4607C109.519 66.6902 109.435 66.9516 109.417 67.2215L109.332 68.952C109.332 68.952 107.361 68.2117 107.196 69.3623C107.031 70.513 106.701 77.6044 106.701 77.6044C106.584 76.4894 106.365 75.3876 106.045 74.3129C105.635 73.2425 103.659 73.2425 103.659 74.2327Z'
      fill='#CCCCCC'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M109.332 68.9697L109.497 76.5339'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M112.294 68.4746L112.379 76.6141'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M114.845 70.8608L114.68 76.534'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M108.267 76.4536L115.75 76.7792'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M106.701 77.604C106.703 77.9144 106.777 78.22 106.918 78.497C107.058 78.774 107.26 79.0149 107.508 79.2007C108.32 79.7091 110.149 81.0293 110.349 83.1612'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M38.7438 199.139H32.3838L40.8623 140.04H47.2222L38.7438 199.139Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path
      d='M51.4638 199.139H57.8193L49.3409 140.04H42.9854L51.4638 199.139Z'
      fill='white'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path
      d='M128.975 199.139H122.619L131.093 140.04H137.453L128.975 199.139Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path
      d='M141.694 199.139H148.05L139.571 140.04H133.216L141.694 199.139Z'
      fill='white'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path
      d='M146.136 130.771H24.8555V142.528H146.136V130.771Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path
      d='M149.093 130.771H27.8125V142.528H149.093V130.771Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeMiterlimit='10'
    />
    <path d='M29.7969 142.528H40.0906L45.4113 130.771H35.1177L29.7969 142.528Z' fill='#263238' />
    <path d='M70.5441 130.771H60.2505L54.9297 142.528H65.2234L70.5441 130.771Z' fill='#263238' />
    <path d='M145.935 130.771H135.642L130.325 142.528H140.619L145.935 130.771Z' fill='#263238' />
    <path d='M120.808 130.771H110.51L105.193 142.528H115.487L120.808 130.771Z' fill='#263238' />
    <path d='M95.676 130.771H85.3823L80.0615 142.528H90.3552L95.676 130.771Z' fill='#263238' />
    <path d='M148.428 135.985H128.358' stroke='#263238' strokeWidth='1.16634' strokeMiterlimit='10' />
    <path d='M148.077 137.952H144.91' stroke='#263238' strokeWidth='1.16634' strokeMiterlimit='10' />
    <path d='M27.2998 137.461H47.3698' stroke='#263238' strokeWidth='1.16634' strokeMiterlimit='10' />
    <path d='M27.6523 135.25H53.003' stroke='#263238' strokeWidth='1.16634' strokeMiterlimit='10' />
    <path
      d='M182.195 196.561H147.791V199.143H182.195V196.561Z'
      fill='#263238'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M176.388 196.49H153.598L162.531 147.894H167.455L176.388 196.49Z'
      fill='#00768D'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M159.213 165.957H170.778L169.667 159.936H160.319L159.213 165.957Z'
      fill='white'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M172.963 188.377L173.534 191.7'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M166.456 150.578L172.245 184.198'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M200.704 199.139H203.786'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.5586 199.139H196.4'
      stroke='#263238'
      strokeWidth='1.16634'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default AccessDeniedIcon;

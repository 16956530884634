import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { WPToastNotification, WPToastNotificationProvider } from "@components/WPToastNotification";

import WPErrorBoundary from "@common/utils/WPErrorBoundary";

import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/fonts.css";
import "./index.scss";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <WPErrorBoundary >
        <WPToastNotificationProvider>
          <WPToastNotification />
          <App />
        </WPToastNotificationProvider>
      </WPErrorBoundary>
    </Provider>
  </BrowserRouter>,
);
reportWebVitals();

import WPHeaderFooterPage from "@components/WPHeaderFooterPage";

import Loader from "@common/utils/Loader";

const FullScreenLoader = () => (
  <WPHeaderFooterPage>
    <div className='loader'>
      <Loader />
    </div>
  </WPHeaderFooterPage>
);

export default FullScreenLoader;

import React from "react";

import SVGContainer from "../SVGContainer";

const MinimizeIcon = () => (
  <SVGContainer width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.4297 15.3223H9.57254C9.49397 15.3223 9.42969 15.3866 9.42969 15.4651V16.5366C9.42969 16.6151 9.49397 16.6794 9.57254 16.6794H22.4297C22.5083 16.6794 22.5725 16.6151 22.5725 16.5366V15.4651C22.5725 15.3866 22.5083 15.3223 22.4297 15.3223Z'
      fill='black'
      fillOpacity='0.85'
    />
  </SVGContainer>
);

export default MinimizeIcon;

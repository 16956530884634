import React from "react";

const MinervaIcon = () => (
  <div style={{ height: "72px", width: "72px", marginBottom: "50px" }}>
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 217.6 202.1'
      style={{ enableBackground: "new 0 0 217.6 202.1" }}
      xmlSpace='preserve'
    >
      <style type='text/css'>
        {`
        .st0{fill:#FFFFFF;}
        .st1{fill:#00A79D;}
        .st2{fill:#808285;}
      `}
      </style>
      <g id='Layer_1'></g>
      <g id='Layer_2'>
        <g>
          <circle className='st0' cx='103.6' cy='101.9' r='96.7' />
          <path
            className='st1'
            d='M200.5,101.9C199.7,188.5,96.6,231.8,34.6,171C-3.6,133.7-3.7,70.1,34.2,32.5C96.6-29,200.1,14.7,200.5,101.9
           L200.5,101.9z M200.2,101.9C200.1,34,129.5-12,67.2,14c-35,14.1-59.4,50.1-59.1,87.9c-0.2,68.1,69.3,114.5,132.3,88.8
           C175.9,176.8,200.7,140.2,200.2,101.9L200.2,101.9z'
          />
        </g>
        <g>
          <g>
            <g>
              <path
                className='st1'
                d='M122.2,10.6c-12.1,1.7-19.4,4-19.4,4l-12.2,8.1l-12,30.9c0,0,0.3,24.2,0.3,25c0,0.8,17.9,35.6,17.9,35.6
                   l19.2,25.2l17.4,19l10.4,24.5c0,0,7.5-6.6,11.9-33.1c4.4-26.5,22.1-42.6,22.1-42.6l12.4-29.6l0.4-3.5l-8.2-29L154,16.7
                   l-18.3-5.1C135.6,11.6,127.6,11.8,122.2,10.6z'
              />
              <g id='v0amru_00000030456777675059268120000002797768473644426173_'>
                <g>
                  <path
                    className='st2'
                    d='M143.8,182.9c-4-4.8-7.1-9.3-10.9-13c-12.5-12.2-27.2-16.1-44.3-11.2c-5.5,1.6-11.2,2.8-16.8,2.9
                           c-10.1,0.2-14.9-6.7-12.9-16.4c0.4-1.7-0.3-4.7-1.6-5.6c-5.2-3.8-5.7-5.2-1.6-10.5c-5.2-3.9-5.1-3.9-1.5-9.9
                           c2.1-3.5,0.6-6.4-2.2-8.6c-2-1.6-4.4-2.8-6.6-4.2c-2.9-1.9-3.3-4.3-0.9-7c1.8-1.9,3.7-3.7,5.5-5.6c2.3-2.4,4.7-4.6,6.7-7.2
                           c5.6-7.1,5.3-10.1-1.9-16.5c2.2,0.5,3.6,0.9,5.8,1.4c12.5-52,41.9-56.8,41.9-56.8C77.3,35.1,76.4,70,91.9,99.3
                           c5.9,11.1,13.6,21.1,21.7,31c6.6,8.1,14,15.6,20.4,23.8C140.4,162.2,143.7,171.7,143.8,182.9z'
                  />
                </g>
              </g>
              <path
                className='st2'
                d='M150.2,77.5c0.1,1.3,0.2,2.6,0.2,3.9c0,10-4.2,19-10.9,25.3c-1.3,3.4-2.1,6.8-2.1,10
                   c0,16.1,18.4,33,18.4,33c6.1-22.3,10.1-20.2,20.3-36.6c12.2-19.7,14-33.7,14.3-35.6H150.2z'
              />
              <path
                className='st2'
                d='M115.5,58.8C103,58.8,92.9,69,92.9,81.5c0,12.5,10.1,22.6,22.6,22.6c12.5,0,22.6-10.1,22.6-22.6
                   C138.1,69,128,58.8,115.5,58.8z M121.6,90.7c-5.1,0-9.3-4.2-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3c5.1,0,9.3,4.2,9.3,9.3
                   C130.9,86.6,126.7,90.7,121.6,90.7z'
              />
              <path
                className='st2'
                d='M149.8,74h41c0.3-3.9,1.1-26-17.5-46.4C154.4,7.1,124,10.5,124,10.5s-26.2,0.6-31.6,37.7
                   c-0.5,3.5,0.7,4.6,3.1,4.7c5.7-4,12.6-6.3,20-6.3c16,0,29.5,10.8,33.6,25.4C149.5,73,149.7,73.7,149.8,74z'
              />
            </g>
          </g>
        </g>
        <g>
          <polygon className='st1' points='130,21.4 180.7,21.9 130,22.4 130,21.4' />
          <path
            className='st1'
            d='M125.7,26.6c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8S128.3,26.6,125.7,26.6z
            M125.7,18.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8S127.8,18.1,125.7,18.1z'
          />
        </g>
        <g>
          <path
            className='st1'
            d='M114.4,39l14.1,0.1l0.1,0l0.1,0.1l21.6,13c-0.7-0.1,61.6,0.3,61.4,0.2c-0.1,0-61.6,0.3-61.7,0.2
           c0,0-21.7-12.8-21.7-12.8c1.1,0.1-14.1,0.1-13.9,0.1C114.4,40,114.4,39,114.4,39L114.4,39z'
          />
          <path
            className='st1'
            d='M110.2,44.3c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8S112.8,44.3,110.2,44.3z
            M110.2,35.8c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C113.9,37.5,112.2,35.8,110.2,35.8z'
          />
        </g>
        <g>
          <polygon className='st1' points='157.8,36.6 195.1,37.1 157.8,37.6 157.8,36.6' />
          <path
            className='st1'
            d='M153.5,41.9c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8S156.1,41.9,153.5,41.9z M153.5,33.4
           c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C157.3,35.1,155.6,33.4,153.5,33.4z'
          />
        </g>
        <g>
          <polygon className='st1' points='167.8,90.5 207.2,91 167.8,91.5 167.8,90.5' />
          <path
            className='st1'
            d='M163.5,95.8c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8S166.1,95.8,163.5,95.8z M163.5,87.3
           c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S165.6,87.3,163.5,87.3z'
          />
        </g>
        <g>
          <polygon className='st1' points='159.4,112.9 194.4,113.4 159.4,113.9 159.4,112.9' />
          <path
            className='st1'
            d='M155.1,118.2c-2.6,0-4.8-2.1-4.8-4.8s2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8S157.8,118.2,155.1,118.2z M155.1,109.7
           c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8S157.2,109.7,155.1,109.7z'
          />
        </g>
      </g>
    </svg>
  </div>
);

export default MinervaIcon;

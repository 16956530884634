const SortIconDescending = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path
      d='M15.5819 7.43028L13.7618 1.73899C13.75 1.7009 13.7262 1.66764 13.694 1.64412C13.6618 1.6206 13.6229 1.60808 13.583 1.6084H12.0281C11.9457 1.6084 11.8754 1.66064 11.8493 1.73899L10.0152 7.43028C10.0091 7.44836 10.0051 7.46845 10.0051 7.48854C10.0051 7.59099 10.0895 7.67537 10.1919 7.67537H11.325C11.4094 7.67537 11.4817 7.61912 11.5058 7.53876L11.8573 6.30126H13.6453L13.9928 7.53675C14.0149 7.61711 14.0893 7.67336 14.1736 7.67336H15.4031C15.4232 7.67336 15.4413 7.67135 15.4594 7.66532C15.5076 7.64925 15.5457 7.61711 15.5698 7.57291C15.5919 7.52872 15.596 7.47849 15.5819 7.43028ZM12.0402 5.25461L12.6991 2.90617H12.8256L13.4705 5.25461H12.0402ZM14.923 15.1566H12.3194V15.1486L14.9832 11.3537C15.0053 11.3216 15.0174 11.2854 15.0174 11.2452V10.514C15.0174 10.4115 14.933 10.3272 14.8306 10.3272H10.7323C10.6299 10.3272 10.5455 10.4115 10.5455 10.514V11.3778C10.5455 11.4803 10.6299 11.5647 10.7323 11.5647H13.1953V11.5727L10.5214 15.3676C10.4991 15.3993 10.4872 15.4372 10.4873 15.476V16.2073C10.4873 16.3097 10.5716 16.3941 10.6741 16.3941H14.921C15.0234 16.3941 15.1078 16.3097 15.1078 16.2073V15.3435C15.1081 15.319 15.1035 15.2948 15.0943 15.2721C15.0851 15.2494 15.0716 15.2288 15.0544 15.2115C15.0372 15.1941 15.0167 15.1803 14.9942 15.1709C14.9716 15.1615 14.9474 15.1566 14.923 15.1566ZM4.94864 2.07247C4.9336 2.05326 4.9144 2.03772 4.89247 2.02704C4.87054 2.01636 4.84647 2.0108 4.82208 2.0108C4.79768 2.0108 4.77361 2.01636 4.75168 2.02704C4.72975 2.03772 4.71055 2.05326 4.69551 2.07247L2.44551 4.92314C2.36114 5.02961 2.43748 5.1843 2.57208 5.1843H4.09886V15.8316C4.09886 15.92 4.17118 15.9923 4.25958 15.9923H5.38458C5.47297 15.9923 5.54529 15.92 5.54529 15.8316V5.1843H7.07208C7.20667 5.1843 7.28301 5.0276 7.19864 4.92314L4.94864 2.07247Z'
      fill='black'
      fillOpacity='0.85'
    />
  </svg>
);

export default SortIconDescending;

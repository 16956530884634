const SortIconAscending = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path
      d='M15.5819 7.43028L13.7618 1.73899C13.75 1.7009 13.7262 1.66764 13.694 1.64412C13.6618 1.6206 13.6229 1.60808 13.583 1.6084H12.0281C11.9457 1.6084 11.8754 1.66064 11.8493 1.73899L10.0152 7.43028C10.0091 7.44836 10.0051 7.46845 10.0051 7.48854C10.0051 7.59099 10.0895 7.67537 10.1919 7.67537H11.325C11.4094 7.67537 11.4817 7.61912 11.5058 7.53876L11.8573 6.30126H13.6453L13.9928 7.53675C14.0149 7.61711 14.0893 7.67336 14.1736 7.67336H15.4031C15.4232 7.67336 15.4413 7.67135 15.4594 7.66532C15.5076 7.64925 15.5457 7.61711 15.5698 7.57291C15.5919 7.52872 15.596 7.47849 15.5819 7.43028ZM12.0402 5.25461L12.6991 2.90617H12.8256L13.4705 5.25461H12.0402ZM14.923 15.1566H12.3194V15.1486L14.9832 11.3537C15.0053 11.3216 15.0174 11.2854 15.0174 11.2452V10.514C15.0174 10.4115 14.933 10.3272 14.8306 10.3272H10.7323C10.6299 10.3272 10.5455 10.4115 10.5455 10.514V11.3778C10.5455 11.4803 10.6299 11.5647 10.7323 11.5647H13.1953V11.5727L10.5214 15.3676C10.4991 15.3993 10.4872 15.4372 10.4873 15.476V16.2073C10.4873 16.3097 10.5716 16.3941 10.6741 16.3941H14.921C15.0234 16.3941 15.1078 16.3097 15.1078 16.2073V15.3435C15.1081 15.319 15.1035 15.2948 15.0943 15.2721C15.0851 15.2494 15.0716 15.2288 15.0544 15.2115C15.0372 15.1941 15.0167 15.1803 14.9942 15.1709C14.9716 15.1615 14.9474 15.1566 14.923 15.1566ZM7.07208 12.8182H5.54529V2.1709C5.54529 2.08251 5.47297 2.01019 5.38458 2.01019H4.25958C4.17118 2.01019 4.09886 2.08251 4.09886 2.1709V12.8182H2.57208C2.43748 12.8182 2.36114 12.9749 2.44551 13.0794L4.69551 15.9301C4.71055 15.9493 4.72975 15.9648 4.75168 15.9755C4.77361 15.9862 4.79768 15.9917 4.82208 15.9917C4.84647 15.9917 4.87054 15.9862 4.89247 15.9755C4.9144 15.9648 4.9336 15.9493 4.94864 15.9301L7.19864 13.0794C7.281 12.9749 7.20667 12.8182 7.07208 12.8182Z'
      fill='black'
      fillOpacity='0.85'
    />
  </svg>
);

export default SortIconAscending;

import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { WPTypogrpahy } from "@components/WPTypography";

import CustomTooltip from "@common/styles/components/tooltip/CustomTooltip";

import { WPHeadingSize, WPHeadingSizeFontMapping, WPHeadingTypes } from "./WPHeading.constant";

/**
 * WPHeading component
 *
 * @param {Object} props - Component props
 * @param {'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'} props.size - Size of the heading
 * @param {'default' | 'primary'} props.type - Type of the heading
 * @param {string} props.text - Text content of the heading
 * @param {React.ReactNode} props.children - Child elements
 * @param {string} [props.color] - Custom color for the heading
 * @param {boolean} [props.secondary] - Whether to use secondary font weight
 */
const WPHeading = ({
  size,
  type,
  text,
  children,
  color,
  secondary,
  className,
  tooltip,
  wrapContent,
  tooltipPlacement = "top",
  renderHtml,
  fontNoto,
}) => {
  const nodeRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  /**
   * UseEffect hoos determine if the content overflows its container.
   * This will update whenever the `children` or `text` props change.
   * If its value is true, then tooltip will be on hover of item else not
   */
  useEffect(() => {
    let isOverFlow = false;
    if (nodeRef?.current) {
      isOverFlow = nodeRef.current.scrollWidth > nodeRef.current.clientWidth;
    }
    setIsOverflowing(isOverFlow);
  }, [children, text, nodeRef?.current]);

  return (
    <CustomTooltip text={isOverflowing ? tooltip : ""} placement={tooltipPlacement}>
      <div className='max-width-100'>
        <WPTypogrpahy
          ref={nodeRef}
          variant={size}
          text={text}
          color={color || WPHeadingTypes[type]}
          asElement={WPHeadingSize[size]}
          className={`${className} ${WPHeadingSizeFontMapping[size]} ${wrapContent ? "text-overflow-ellipsis" : ""}`}
          fontFamily={fontNoto ? "NotoSans" : "Diodrum"}
          renderHtml={renderHtml}
          fontWeight={secondary ? "400" : "600"}
        >
          {children}
        </WPTypogrpahy>
      </div>
    </CustomTooltip>
  );
};

WPHeading.propTypes = {
  size: PropTypes.oneOf(["xxSmall", "xSmall", "small", "medium", "large", "xLarge", "xxLarge"]),
  type: PropTypes.oneOf(["default", "primary"]),
  text: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  secondary: PropTypes.bool,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  wrapContent: PropTypes.bool,
  renderHtml: PropTypes.string,
  fontNoto: PropTypes.boolean,
};

WPHeading.defaultProps = {
  size: "medium",
  type: "primary",
  text: "",
  children: null,
  color: null,
  secondary: false,
  className: "",
  tooltip: "",
  wrapContent: true,
  renderHtml: null,
  fontNoto: false,
};

export default WPHeading;

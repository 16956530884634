import React, { useEffect, useState } from "react";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import styled from "styled-components";

import { InputErrorIcon } from "@components/SVGs";

import { colors, fontStyles } from "../../Theme";

const StyledTooltip = styled(Tooltip)`
  padding-left: ${({ type }) => (type === "error" ? "0 !important" : "auto")};

  .error {
    svg {
      padding-right: 5px !important;
      path {
        fill: white !important;
      }
    }
  }

  .tooltip-inner {
    background-color: ${({ type }) => (type === "error" ? "var(--du-color-red)" : colors.DefaultBackground)};
    text-align: left;
    ${fontStyles.diodrum12}
    color: ${colors.White};

    ${({ type }) =>
    type === "error" &&
    `
        svg {
          margin-right: 5px !important;
              margin-bottom: 2px;
          path {
            fill: white !important;
            stroke: 1.5;
          }
        }
      `}
  }

  .tooltip-arrow::before {
    border-top-color: ${({ type }) => (type === "error" ? "var(--du-color-red)" : colors.DefaultBackground)} !important;
  }
`;

const StyledOverlayTrigger = styled(OverlayTrigger)`
  border-radius: 2px;
  text-align: left;
`;

const CustomTooltip = ({ text, children, placement = "top", type }) => {
  const [show, setShow] = useState(true);

  const renderTooltip = isError => (
    <StyledTooltip id='tooltip' type={type} className='error'>
      {isError && <InputErrorIcon />}
      {text}
    </StyledTooltip>
  );

  useEffect(() => setShow(Boolean(text)), [text]);

  return text ? (
    type === "error" ? (
      <StyledOverlayTrigger show={show} placement='top-start' overlay={renderTooltip(true)}>
        {children}
      </StyledOverlayTrigger>
    ) : (
      <StyledOverlayTrigger placement={placement} overlay={renderTooltip()}>
        {children}
      </StyledOverlayTrigger>
    )
  ) : (
    children
  );
};

export default CustomTooltip;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  apiError: null
};

export const ApiDataSlice = createSlice({
  name: "apiData",
  initialState,
  reducers: {
    setApiData: (state, action) => {
      const { key, data } = action.payload;
      state[key] = data;
    },
    setApiError: (state, action) => {
      state.apiError = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setApiData, setApiError } = ApiDataSlice.actions;

export default ApiDataSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: "masterdata/api/v1/ProjectRecent",
  activeMenu: "home",
  breadcrumbData: null
};
export const SideMenuSlice = createSlice({
  name: "leftpanel",
  initialState,
  reducers: {
    updateLeftpanel: (state, action) => {
      state.data = action.payload;
    },
    updateActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    setSideMenu: (state, action) => ({ ...state, ...action.payload }),
  }
});

export const { updateLeftpanel, updateActiveMenu, setSideMenu } = SideMenuSlice.actions;

export default SideMenuSlice.reducer;

import API_ERROR_CODE, { HTTP_STATUS_CODE } from "@constants/api.errorcode";

export const ERROR_PAGE_STRINGS = {
    contactUs: "Contact Us",
    loginAgain: "Login Again",
    refresh: "Refresh"
};

const FULL_SCREEN_ERROR_STRINGS = {
    [HTTP_STATUS_CODE.UNAUTHORIZED]: {
        title: "Unauthorized Access",
        message: "Your session may have expired. Please log in to continue or contact support for assistance.",
    },
    [HTTP_STATUS_CODE.FORBIDDEN]: {
        title: "Access Denied",
        message: "Contact your administrator to request the required permissions.",
    },
    [HTTP_STATUS_CODE.SERVICE_UNAVAILABLE]: {
        title: "WAVE PRO currently down for maintenance",
        message: "WAVE PRO will be available in ${time}. Please reach out to us for any further help & support.",
    },
    [API_ERROR_CODE.APP_CRASH]: {
        title: "Unexpected Error",
        message: "The WAVE PRO may no longer functional as expected. Please click on refresh button to continue."
    }
};

export default FULL_SCREEN_ERROR_STRINGS;
import { useMemo } from "react";

import API_ERROR_CODE, { HTTP_STATUS_CODE } from "@constants/api.errorcode";
import { EXTERNAL_URLS } from "@constants/api.urls";

import { AccessDeniedIcon, UnauthorizedAccessIcon, UnderMaintenaceIcon, UnexpectedErrorIcon } from "@components/SVGs";
import WPButton from "@components/WPButton";
import WPHeaderFooterPage from "@components/WPHeaderFooterPage";
import WPHeading from "@components/WPHeading";

import Footer from "@common/footer/Footer";

import FULL_SCREEN_ERROR_STRINGS, { ERROR_PAGE_STRINGS } from "./WPErrorFullPage.strings";

import "./WPErrorFullPage.scss";

const ERROR_PAGE_ICONS = {
  [HTTP_STATUS_CODE.SERVICE_UNAVAILABLE]: <UnderMaintenaceIcon />,
  [HTTP_STATUS_CODE.UNAUTHORIZED]: <UnauthorizedAccessIcon />,
  [HTTP_STATUS_CODE.FORBIDDEN]: <AccessDeniedIcon />,
  [API_ERROR_CODE.APP_CRASH]: <UnexpectedErrorIcon />,
};

const WPErrorFullPage = ({ errorData }) => {
  const handleContatUs = () => {
    window.open(EXTERNAL_URLS.contactDupontUrl, "_blank");
  };

  const handleLoginBtnClick = () => window.location.reload();

  const { title, message } = useMemo(() => {
    const { data, status } = errorData;
    const result = FULL_SCREEN_ERROR_STRINGS[status];
    if (status === HTTP_STATUS_CODE.SERVICE_UNAVAILABLE) {
      const time = data.estimatedTime || "24H";
      result.message = result.message.replace("${time}", time);
    }
    return result;
  }, [errorData]);

  const isAppCrash = useMemo(() => errorData.status === API_ERROR_CODE.APP_CRASH, [errorData.status]);

  const refreshBtnText = useMemo(() => {
    const isForbidden = errorData.status === HTTP_STATUS_CODE.UNAUTHORIZED;

    if (isForbidden) {
      return ERROR_PAGE_STRINGS.loginAgain;
    }

    if (isAppCrash) {
      return ERROR_PAGE_STRINGS.refresh;
    }

    return null;
  }, [errorData.status]);

  return (
    <WPHeaderFooterPage>
      <div className='wavepro-fullpage-error-container'>
        {ERROR_PAGE_ICONS[errorData.status]}
        <WPHeading type='default'>{title}</WPHeading>
        <WPHeading className='message' secondary type='default' wrapContent={false}>
          {message}
        </WPHeading>
        <div className='button-container'>
          {!isAppCrash && (
            <WPButton id='error-contact-us-btn' variant='outlined' onClick={handleContatUs}>
              {ERROR_PAGE_STRINGS.contactUs}
            </WPButton>
          )}
          {refreshBtnText && (
            <WPButton id='error-login-again-btn' variant='contained' onClick={handleLoginBtnClick}>
              {refreshBtnText}
            </WPButton>
          )}
        </div>
      </div>
      <Footer />
    </WPHeaderFooterPage>
  );
};

export default WPErrorFullPage;

const WP_App_Routes = Object.freeze({
    base: "/",
    wildcard: "*",
    help: "/help",
    home: "/home",
    about: "/about",
    logout: "/logout",
    project: "/project",
    academy: "/academy",
});

const { about, academy, help } = WP_App_Routes;
export const WP_Public_Routes = [about, academy, help];

export default WP_App_Routes;
import React from "react";

const StarIcon = () => (
  <>
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <g clipPath='url(#clip0_2414_20038)'>
        <path
          d='M13.5784 4.51837L9.61119 3.9418L7.83775 0.34649C7.78931 0.248053 7.70962 0.168365 7.61119 0.119928C7.36431 -0.00194734 7.06431 0.0996152 6.94087 0.34649L5.16744 3.9418L1.20025 4.51837C1.09087 4.53399 0.990874 4.58555 0.914312 4.66368C0.821752 4.75881 0.770747 4.8868 0.772505 5.01952C0.774263 5.15225 0.828639 5.27884 0.923686 5.37149L3.794 8.16993L3.11587 12.1215C3.09997 12.2134 3.11014 12.308 3.14524 12.3944C3.18033 12.4808 3.23894 12.5557 3.31442 12.6105C3.3899 12.6653 3.47923 12.6979 3.57228 12.7045C3.66533 12.7112 3.75838 12.6916 3.84087 12.6481L7.38931 10.7824L10.9377 12.6481C11.0346 12.6996 11.1471 12.7168 11.2549 12.6981C11.5268 12.6512 11.7096 12.3934 11.6627 12.1215L10.9846 8.16993L13.8549 5.37149C13.9331 5.29493 13.9846 5.19493 14.0002 5.08555C14.0424 4.81212 13.8518 4.55899 13.5784 4.51837ZM9.77681 7.77618L10.3409 11.0621L7.38931 9.51212L4.43775 11.0637L5.00181 7.77774L2.61431 5.44962L5.91431 4.96993L7.38931 1.98087L8.86431 4.96993L12.1643 5.44962L9.77681 7.77618Z'
          fill='#000000'
        />
      </g>
      <defs>
        <clipPath id='clip0_2414_20038'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  </>
);

export default StarIcon;

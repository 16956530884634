import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  unreadNotifications: [],
  marketSegments: [],
  waterLibraryList: {},
  chemicalLibraryList: [],
  chemicalCategoryData: {},
  fullScreenLoaderMessage: null,
  filter: {
    isIX: false,
    isUF: false,
    selectedBrand: "All",
    filterText: "",
  }
};

export const GlobalDataSlice = createSlice({
  name: "GlobalData",
  initialState,
  reducers: {
    updateGlobalData: (state, action) => {
      const { type, value } = action.payload;
      state[type] = value;
    },
    updateHomeFilterData: (state, { payload }) => {
      state.filter = { ...state.filter, ...payload };
    },
    resetFilter: state => {
      state.filter = {
        isIX: false,
        isUF: false,
        selectedBrand: "All",
        filterText: "",
      };
    },
  },
});

export const { updateGlobalData, updateHomeFilterData, resetFilter } = GlobalDataSlice.actions;

export default GlobalDataSlice.reducer;

export const CURRENCY_UNIT = "currency";
export const DEFAULT_CURRENCY_SYMBOL = "$";
export const DEFAULT_EXCHANGE_RATE = 1;

export const UNITS = {
  celsius: "°C",
  farhenite: "°F",
  meter: "m",
  millimeter: "mm",
  centimeter: "cm",
  inch: "in",
  kilowatt: "kW",
  kiloWattHourPerDay: "kWh/d",
  kiloWattHour: "kWh",
  ppmv: "ppmv", //parts per million by volume
  gcm3: "g/cm³", //grams per cubic centimeter
  lbgl: "lb/gal", //pounds per gallon
  uscm: "µS/cm", //microsiemens per centimeter
  mscsm: "mS/cm", //millisiemens per centimeter
  bar: "bar",
  psi: "psi",
  CUBIC_METER: "m³",
  METER: "m",
  BV_PER_HR: "BV/h",
  BV: "BV",
  GRAMS_PER_LITRE: "g/L",
  MICROGRAM_LITRE: "µg/L",
  MiliEquivalentPerLiter: "meq/L",
  MiligramPerLiter: "mg/L",
  PartsPerMillion: "ppm CaC03",
  MicroAtmosphere: "µatm",
  meterCubePerHour: "m³/h",
  lmh: "LMH",
  squareMeter: "m²",
  kiloGram: "kg",
  meterPerHour: "m/h",
  normalMeterCubePerHour: "Nm³/h",
  toc: "mg/L TOC",
  organicsKMnO4: "mg/L KMnO₄",
  pH: "pH",
  min: "min",
  hour: "h",
  day: "d",
  sec: "s",
  gallonsPerMin: "gpm",
  gallonsPerDay: "gpd",
  meterCubePerDay: "m³/d",
  literPerSecond: "L/s",
  kiloPascal: "kPa",
  gfd: "gfd",
  squareFoot: "ft²",
  horsePower: "hp",
  gpmft3: "gpm/ft³",
  liter: "L",
  gallon: "gal",
  killoGallon: "Kgal",
  cubicFoot: "ft³",
  pound: "lb",
  scfm: "scfm",
  literPerHour: "L/h",
  ntu: "NTU",
  percentage: "%",
};

/** @deprecated use UNIT_TYPES instead */
export const SELECTED_UNIT = {
  TEMPERATURE: 2,
  PRESSURE: 3,
  LENGTH: 8,
  VOLUME: 12,
  SPECIFIC_VELOCITY: 10,
  VOLUME_COMMON: 13,
  REGENERATION_DOSE: 14,
  CONDUCTIVITY: 17,
};

// this const is similar to above selected unit, prefer this for better readability
export const UNIT_TYPES = {
  FLOW: 1,
  TEMPERATURE: 2,
  PRESSURE: 3,
  FLUX: 4,
  AREA: 5,
  GASES_CONCENTRATION: 6,
  DENSITY: 7,
  LENGTH: 8,
  POWER: 9,
  SPECIFIC_VELOCITY: 10,
  VOLUME_SOLUTION: 11,
  VOLUME_RESIN: 12,
  VOLUME_COMMON: 13,
  REGENERANT_DOSE: 14,
  LINEAR_VELOCITY: 15,
  WEIGHT: 16,
  CONDUCTIVITY: 17,
  GAS_FLOW: 18,
  ORGANICS: 19,
};

export const METRIC_UNITS = {
  [UNIT_TYPES.FLOW]: UNITS.meterCubePerHour,
  [UNIT_TYPES.PRESSURE]: UNITS.bar,
  [UNIT_TYPES.TEMPERATURE]: UNITS.celsius,
  [UNIT_TYPES.FLUX]: UNITS.lmh,
  [UNIT_TYPES.AREA]: UNITS.squareMeter,
  [UNIT_TYPES.GASES_CONCENTRATION]: UNITS.MicroAtmosphere,
  [UNIT_TYPES.CONDUCTIVITY]: UNITS.uscm,
  [UNIT_TYPES.DENSITY]: UNITS.gcm3,
  [UNIT_TYPES.LENGTH]: UNITS.millimeter,
  [UNIT_TYPES.POWER]: UNITS.kilowatt,
  [UNIT_TYPES.SPECIFIC_VELOCITY]: UNITS.BV_PER_HR,
  [UNIT_TYPES.VOLUME_SOLUTION]: UNITS.CUBIC_METER,
  [UNIT_TYPES.VOLUME_RESIN]: UNITS.CUBIC_METER,
  [UNIT_TYPES.VOLUME_COMMON]: UNITS.BV,
  [UNIT_TYPES.REGENERANT_DOSE]: UNITS.GRAMS_PER_LITRE,
  [UNIT_TYPES.LINEAR_VELOCITY]: UNITS.meterPerHour,
  [UNIT_TYPES.WEIGHT]: UNITS.kiloGram,
  [UNIT_TYPES.GAS_FLOW]: UNITS.normalMeterCubePerHour,
  [UNIT_TYPES.ORGANICS]: UNITS.toc,
};

export const UNIT_PRECISION_MAPPING = {
  [UNITS.gallonsPerDay]: 1,
  [UNITS.gallonsPerMin]: 1,
  [UNITS.literPerSecond]: 1,
  [UNITS.meterCubePerDay]: 1,
  [UNITS.meterCubePerHour]: 1,
  [UNITS.literPerSecond]: 1,
  [UNITS.literPerHour]: 1,
  [UNITS.bar]: 2,
  [UNITS.psi]: 2,
  [UNITS.kiloPascal]: 2,
  [UNITS.celsius]: 1,
  [UNITS.farhenite]: 1,
  [UNITS.gfd]: 0,
  [UNITS.lmh]: 0,
  [UNITS.squareFoot]: 0,
  [UNITS.squareMeter]: 0,
  [UNITS.ppmv]: 0, //  unkown precision
  [UNITS.MicroAtmosphere]: 0, //  unkown precision
  [UNITS.uscm]: 0, //  unkown precision
  [UNITS.mscsm]: 0, //  unkown precision
  [UNITS.lbgl]: 0, //  unkown precision
  [UNITS.gcm3]: 0, //  unkown precision
  [UNITS.inch]: 1,
  [UNITS.centimeter]: 1,
  [UNITS.millimeter]: 0,
  [UNITS.meter]: 3,
  [UNITS.horsePower]: 2, // to confirm
  [UNITS.kilowatt]: 2,
  [UNITS.gpmft3]: 0, //  unkown precision
  [UNITS.BV_PER_HR]: 0, //  unkown precision
  [UNITS.gallon]: 1,
  [UNITS.liter]: 0,
  [UNITS.CUBIC_METER]: 3,
  [UNITS.cubicFoot]: 0, // unkown precision
  [UNITS.BV]: 0, //  unkown precision
  [UNITS.gpmft3]: 0, //  unkown precision
  [UNITS.meterPerHour]: 0, //  unkown precision
  [UNITS.pound]: 0,
  [UNITS.kiloGram]: 0,
  [UNITS.scfm]: 1,
  [UNITS.normalMeterCubePerHour]: 1,
  [UNITS.MICROGRAM_LITRE]: 0, //  unkown precision
  [UNITS.MiligramPerLiter]: 1,
  [UNITS.day]: 0,
  [UNITS.hour]: 0,
  [UNITS.min]: 1,
  [UNITS.sec]: 0,
  [UNITS.pH]: 1,
  [UNITS.ntu]: 1,
};

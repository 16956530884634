import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { store } from "@app/store";

import { setApiError } from "@common/ApiSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PATH,
  prepareHeaders: (headers, { getState }) => {
    const idToken = getState().Auth?.idToken;
    if (idToken) {
      headers.set("Authorization", `Bearer ${idToken}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    //const error = new Error(`API Error: ${result.error.status} - ${JSON.stringify(result.error.data, null, 2)}`);
    const { error } = result;
    error.type = "ApiError";
    store.dispatch(setApiError(error));
    throw error;
  }
  return result;
};

const headers = { "Content-type": "application/json; charset=UTF-8" };

// Utility function to simplify mutation configurations
const createMutation = method => data => {
  const { Method, ...payload } = data;
  return {
    method,
    url: `/${Method}`,
    headers,
    body: payload,
  };
};

export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: baseQueryWithInterceptor,
  endpoints: builder => ({
    getAllData: builder.query({
      query: urlname => ({
        url: urlname,
        method: "GET",
      }),
    }),
    deleteData: builder.mutation({ query: createMutation("DELETE") }),
    deleteDataRecord: builder.mutation({ query: createMutation("PUT") }),
    createData: builder.mutation({ query: createMutation("POST") }),
    updateData: builder.mutation({ query: createMutation("PUT") }),
    calcEngineData: builder.mutation({ query: createMutation("POST") }),
    chemicalAdjustmentData: builder.mutation({ query: createMutation("POST") }),
  }),
});

export const {
  useLazyGetAllDataQuery,
  useCreateDataMutation,
  useCalcEngineDataMutation,
  useDeleteDataRecordMutation,
  useDeleteDataMutation,
  useUpdateDataMutation,
  useChemicalAdjustmentDataMutation,
} = postApi;
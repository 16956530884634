import React from "react";

import SVGContainer from "../SVGContainer";

const ExternalIcon = () => (
  <SVGContainer xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 8H8.46875C8.20987 8 8 8.20987 8 8.46875V23.5312C8 23.7901 8.20987 24 8.46875 24H23.5312C23.7901 24 24 23.7901 24 23.5312V16H23V23H9V9H16V8Z'
      fill='black'
    />
    <path d='M18 8.66675H23.099C23.2284 8.66675 23.3333 8.77168 23.3333 8.90112V14.0001' stroke='black' />
    <path
      d='M22.999 8.87144C22.999 8.94126 11.9479 19.2725 11.9479 19.2725C11.9479 19.2725 23 9.01102 23 8.94121'
      stroke='black'
    />
  </SVGContainer>
);

export default ExternalIcon;
